import twitch from "@assets/twitch.svg";
import twitter from "@assets/twitter.svg";
import facebook from "@assets/facebook.svg";
import discord from "@assets/discord.svg";
import linkedin from "@assets/linkedin.svg";
import youtube from "@assets/youtube.svg";
import steam from "@assets/steam.svg";

export default {
  navigation: {
    menu: {
      binxLogo: {
        src: require("../assets/binx-tv-logo.png"),
        alt: "Binx TV Link",
        width: 191,
        height: 51,
      },
      menuLabels: [
        { label: "Home", route: "/" },
        { label: "Packs", route: "/packs" },
        {
          label: "Staking",
          link: "https://staking.binxtv.io/",
        },
        {
          label: "Gaming",
          link: "https://play.binxtv.io/",
        },
        {
          label: "Shop",
          link: "https://shop.binxtv.io/",
        },
        {
          label: "FairDraw",
          link: "https://fairdraw.binxtv.io/",
        },
      ],
    },
    footer: {
      copyright:
        "Binx is a new kind of social streaming community. Focused on the content creators. Building apps, tools and services dedicated to enhancing social video streaming all over the world.\n \n \nBinx © 2021 and beyond.",
      links: [
        {
          label: "Privacy Policy",
          href: "https://www.binx.pro/privacy-policy/",
        },
        {
          label: "Community Standards",
          href: "https://www.binx.pro/community-standards/",
        },
        {
          label: "Terms of Service",
          href: "https://www.binx.pro/terms-of-service/",
        },
        {
          label: "Returns & Refunds",
          href: "https://binx.pro/return-refund-policy/",
        },
      ],
    },
  },
  socials: [
    {
      icon: twitch,
      aria: "Twitch link",
      link: "https://www.twitch.tv/binxtv",
    },
    {
      icon: twitter,
      aria: "Twitter  link",
      link: "https://twitter.com/binxtv",
    },
    {
      icon: facebook,
      aria: "Facebook  link",
      link: "https://www.facebook.com/binxtv/",
    },
    {
      icon: discord,
      aria: "Discord  link",
      link: "https://discord.gg/spVVMcSkpR",
    },
    {
      icon: linkedin,
      aria: "Linkedin  link",
      link: "https://www.linkedin.com/company/binxtv",
    },
    {
      icon: youtube,
      aria: "Youtube  link",
      link: "https://www.youtube.com/channel/UCLbojHZqAWodqSK453J5zHw",
    },
    {
      icon: steam,
      aria: "Steam  link",
      link: "https://steamcommunity.com/groups/binxtv",
    },
  ],
  background: {
    src: require("../assets/bg.png"),
    placeholder: require("../assets/bg-placeholder.png"),
    alt: "Page Background",
    width: 1366,
    height: 767,
  },
};

export const pastpackLinks = ["marketplace", "unpack", "blend"];
